<template>
	<b-card title="Building Category">
		<b-row class="mb-3">

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="name">Name:</label>
					<input 
						id="name"
						type="text" 
						class="form-control"
						placeholder="Search: name"
						v-model="filter.keyword" 
					>
				</div>
			</b-col>
			
			<b-col cols="12" class="mt-2">
				<button
					class="btn btn-outline-secondary mr-2"
					@click="filter = {}" 
				>
					Reset
				</button>
				<router-link class="btn btn-primary mr-2" to="/building-material/create-category">
					Tambah Data
				</router-link>
			</b-col>
		</b-row>
		
		<Table 
			:result="result"
			:building-material-category="buildingMaterialCategory"
			:is-loading="isLoading"
			:get-data="getData"
			:delete-item="deleteItem"
			:current-page="currentPage"
			@filter="getFilter"
		/>
	</b-card>
</template>

<script>
import Table from '@/components/building-material-category/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			isLoading: false,
			result: [],
			buildingMaterialCategory: {},
			link:'',
			category: [],
			isLoadingExport: false,
			filter: {},
			resultExport: [],
			sort: '',
			listProvince: [],
			listCity: [],
			gender: [
				{ 
					title: 'Wanita',
					value: 'P'
				}, 
				{ 
					title: 'Laki-laki',
					value: 'L'
				}, 
			],
			filter: {
				name: '',
				email: '',
				city: '',
				province: '',
				gender: '',
				range_age: '',
				start_date: '',
				end_date: '',
				order_type: ''
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	created() {
		this.getData()
	},
	mounted() {
	},
	methods: {
		getFilter(value) {
			this.filter.order_type = value.order_type
		},
		submitFilter() {
			this.getData(this.currentPage)
		},
		clearFilter() {
			this.filter = {
				keyword: '',
				category_uuid: '',
			}
		},
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
            queryParams.with_pagination = true
    		queryParams.page = page
			this.$http.get('/admin/building-category/parent', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.buildingMaterialCategory = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
			})	
		},
		deleteItem(slug) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Are you sure to delete this building category?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				this.$http.delete(`/admin/building-category/parent/${slug}`)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: 'Building Category successfully deleted',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>